/**
 * Generates a 12-character unique hash based on input parameters
 * @param {string} date - ISO date string
 * @param {string} address - Wallet address
 * @param {string} amount - Transaction amount
 * @returns {string} 12-character hash
 */
export const generateHash = async (date, address, amount) => {
  const input = `${date}-${address}-${amount}`;
  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  
  return hashHex.toUpperCase().slice(0, 10);
};

/**
 * Validates if a hash is in the correct format
 * @param {string} hash - Hash to validate
 * @returns {boolean} True if valid
 */
export const validateHash = (hash) => {
  return /^[0-9A-Z]{12}$/.test(hash);
}; 