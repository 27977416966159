import { useTonConnectUI } from '@tonconnect/ui-react';
import { useEffect, useState } from 'react';

export function useTonConnect() {
  const [tonConnectUI] = useTonConnectUI();
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const handleConnectionChange = () => {
      setIsConnected(tonConnectUI.connected);
    };

    // Set initial state
    handleConnectionChange();

    // Subscribe to connection changes
    const unsubscribe = tonConnectUI.onStatusChange(handleConnectionChange);

    return () => {
      // Cleanup subscription using the returned unsubscribe function
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [tonConnectUI]);

  return {
    connected: isConnected,
    wallet: tonConnectUI.account,
    connect: () => tonConnectUI.connectWallet(),
    disconnect: () => tonConnectUI.disconnect(),
    loading: tonConnectUI.loading,
    sendTransaction: (transaction) => tonConnectUI.sendTransaction(transaction)
  };
} 