import { Address } from '@ton/core';
import { Buffer } from './polyfills';

export const formatTON = (amount) => {
  if (!amount && amount !== 0) return '0';
  
  // Convert to string and remove trailing zeros after decimal
  const str = amount.toString();
  const [whole, decimal] = str.split('.');
  
  // If no decimal or all zeros after decimal, return whole number
  if (!decimal) return whole;
  
  // Trim trailing zeros
  const trimmedDecimal = decimal.replace(/0+$/, '');
  
  // If all decimals were zeros, return whole number
  if (!trimmedDecimal) return whole;
  
  // If remaining decimal part is longer than 2 digits and has non-zero digits
  if (trimmedDecimal.length > 2) {
    return `${whole}.${trimmedDecimal}`;
  }
  
  // Otherwise return with 2 decimal places
  return `${whole}.${decimal.padEnd(2, '0').slice(0, 2)}`;
};

export const formatAddress = (address) => {
  if (!address) return '';
  try {
    const addr = Address.parse(address);
    const nonBounceable = addr.toString({ urlSafe: true, bounceable: false });
    return `${nonBounceable.slice(0, 4)}...${nonBounceable.slice(-4)}`;
  } catch (e) {
    console.error('Error parsing address:', e);
    return '';
  }
}; 