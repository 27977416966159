import React, { useState, useRef, useEffect } from 'react';
import './Header.css';
import logo from '/assets/logo.png';
import { useTonConnect } from '../../hooks/useTonConnect';
import { formatAddress } from '../../utils/formatters';
import { Address } from '@ton/core';
import { MIXER_TOKEN_ADDRESS } from '../../utils/constants';

const LINKS = {
  chart: 'https://dexscreener.com/ton/EQAUSQROzbfAEC-x0UmKBybyT35U3WRr6k5daPMizWvY8rOk',
  buy: 'https://app.ston.fi/swap?chartVisible=false&ft=TON&tt=' + MIXER_TOKEN_ADDRESS
};

const Header = ({ mixerBalance, isLoadingBalance }) => {
  const { connected, connect, disconnect, loading, wallet } = useTonConnect();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const dropdownRef = useRef(null);
  const formattedAddress = wallet ? formatAddress(wallet.address) : '';
  const [showTokenMenu, setShowTokenMenu] = useState(false);
  const tokenMenuRef = useRef(null);

  const handleCopyAddress = async () => {
    if (wallet?.address) {
      try {
        const addr = Address.parse(wallet.address);
        const nonBounceable = addr.toString({ urlSafe: true, bounceable: false });
        await navigator.clipboard.writeText(nonBounceable);
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 2000);
      } catch (err) {
        console.error('Failed to copy address:', err);
      }
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
      if (tokenMenuRef.current && !tokenMenuRef.current.contains(event.target)) {
        setShowTokenMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
      window.Telegram.WebApp.disableVerticalSwipes();
    }
  }, []);

  const handleMixerClick = async () => {
    if (!connected && !loading) {
      try {
        await connect();
      } catch (e) {
        console.error('Wallet action error:', e);
      }
    } else if (connected) {
      setShowDropdown(!showDropdown);
    }
  };

  const handleDisconnect = async () => {
    try {
      await disconnect();
      setShowDropdown(false);
    } catch (e) {
      console.error('Disconnect error:', e);
    }
  };

  const buttonText = connected 
    ? `${Number(mixerBalance).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })} $MIXER` 
    : 'Connect Wallet';

  return (
    <header className="header">
      <div className="logo-section">
        <a href="/" className="logo-link">
          <img src={logo} alt="$MIXER" className="logo" />
        </a>
        <span className="brand-text">$MIXER</span>
      </div>
      <nav className="nav-links">
        <a 
          href={LINKS.chart}
          target="_blank" 
          rel="noopener noreferrer" 
          className="nav-button"
        >
          <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path d="M3 3v18h18" strokeWidth="2" strokeLinecap="round"/>
            <path d="M7 14l4-4 4 4 4-4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Chart
        </a>
        <a 
          href={LINKS.buy}
          target="_blank" 
          rel="noopener noreferrer" 
          className="nav-button"
        >
          <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path d="M12 2v20M2 12h20" strokeWidth="2" strokeLinecap="round"/>
          </svg>
          Buy
        </a>
        <a 
          href="https://t.me/ton_mix_bot/bridge"
          target="_blank" 
          rel="noopener noreferrer" 
          className="nav-button"
        >
          <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path d="M4 10l8-7 8 7M4 14l8 7 8-7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Bridge
        </a>
        <a 
          href="https://docs.tonmixer.xyz" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="nav-button"
        >
          <svg className="icon" viewBox="0 0 24 24" fill="currentColor">
            <path d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2ZM18 20H6V4H13V9H18V20ZM8 12V14H16V12H8ZM8 16V18H13V16H8Z"/>
          </svg>
          Docs
        </a>
        <div style={{ marginTop: '1rem' }} className="wallet-container" ref={dropdownRef}>
          <button 
            className={`mixer-button ${connected ? 'connected' : ''}`}
            onClick={handleMixerClick}
            disabled={loading}
          >
            {buttonText}
            {connected && (
              <svg 
                className={`dropdown-arrow ${showDropdown ? 'open' : ''}`} 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor"
              >
                <path d="M6 9l6 6 6-6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            )}
          </button>
          {connected && (
            <button 
              style={{ borderTop: 'none', paddingTop: '0px' }}
              className="wallet-address-container"
              onClick={handleCopyAddress}
              title="Click to copy full address"
            >
              {formattedAddress}
              {showCopied && <span className="copied-tooltip">Copied!</span>}
            </button>
          )}
          {showDropdown && connected && (
            <div className="wallet-dropdown">
              <button style={{ padding: '0' }} className="dropdown-item" onClick={handleDisconnect}>
                <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path d="M9 21H5a2 2 0 01-2-2V5a2 2 0 012-2h4M16 17l5-5-5-5M21 12H9" 
                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Disconnect
              </button>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header; 