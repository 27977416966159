import React, { useState, useEffect } from 'react';
import './PoolCard.css';
import RadioToggle from '../RadioToggle/RadioToggle';
import { useTonConnect } from '../../hooks/useTonConnect';
import { formatTON } from '../../utils/formatters';
import { generateHash } from '../../utils/hashGenerator';
import { Address, beginCell, BitString, Dictionary, toNano, TupleBuilder } from '@ton/core';
import { getHttpEndpoint } from '@orbs-network/ton-access';
import { TonClient } from '@ton/ton';
import { MIXER_TOKEN_ADDRESS } from '../../utils/constants';

const LINKS = {
  buy: 'https://app.ston.fi/swap?chartVisible=false&ft=TON&tt=' + MIXER_TOKEN_ADDRESS
};

const PoolCard = ({ pools, selectedAmount, onPoolChange, amount, fee, requiredMixer, mixerBalance, isLoadingBalance }) => {
  const { connected, connect, disconnect, loading: walletLoading, wallet, sendTransaction } = useTonConnect();
  const [showCopiedHash, setShowCopiedHash] = useState(false);
  const [generatedHash, setGeneratedHash] = useState(null);
  const [isDepositing, setIsDepositing] = useState(false);
  const [withdrawHash, setWithdrawHash] = useState('');
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [withdrawError, setWithdrawError] = useState('');
  const [useRelay, setUseRelay] = useState(false);
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);
  const [finalRecipient, setRecipientAddress] = useState('');
  const [addressError, setAddressError] = useState('');

  const selectedPoolLabel = pools.find(pool => pool.value === selectedAmount)?.label;
  const total = amount + fee;
  const hasSufficientMixer = mixerBalance >= requiredMixer;

  useEffect(() => {
    setGeneratedHash(null);
    setWithdrawHash('');
    setRecipientAddress('');
    setWithdrawError('');
    setWithdrawSuccess(false);
  }, [connected]);

  const handleWalletClick = async () => {
    if (!walletLoading) {
      try {
        if (connected) {
          await disconnect();
        } else {
          await connect();
        }
      } catch (e) {
        console.error('Connection error:', e);
      }
    }
  };

  const handleCopyHash = async () => {
    if (generatedHash) {
      try {
        await navigator.clipboard.writeText(generatedHash);
        setShowCopiedHash(true);
        setTimeout(() => setShowCopiedHash(false), 2000);
      } catch (err) {
        console.error('Failed to copy hash:', err);
      }
    }
  };

  const getClient = async () => {
    const endpoint = await getHttpEndpoint();
    return new TonClient({ endpoint });
  };
  
  const SECRET_HASH = import.meta.env.VITE_SECRET_HASH;

  const getContractData = async (client, address) => {
    const builder = new TupleBuilder()
    builder.writeString(SECRET_HASH);
    const args = builder.build();

    const { stack } = await client.runMethod(
      address,
      'get_storage_data',
      args
    );
    const owner_address = stack.readAddress();
    const recordsCell = stack.readCellOpt();
    const recordsData = recordsCell?.beginParse();
    const recordsRaw = recordsData?.loadDictDirect(
      Dictionary.Keys.BitString(256),
      Dictionary.Values.Cell()
    );
    let records = {};
    
    if (recordsRaw) {
      for (const key of recordsRaw.keys()) {
        const val = recordsRaw.get(key);
        const valData = val.beginParse();
        const amount = valData.loadCoins();
        const keyString = Buffer.from(key.toString(), 'hex').toString('utf8');
        records[keyString] = Number(amount) / (10 ** 9);
      }
    }
    const fee = stack.readNumber();
    return { owner_address, records, fee };
  };

  const getRecordData = async (client, address, uniqueHash) => {
    const builder = new TupleBuilder()
    builder.writeString(uniqueHash);
    const args = builder.build();

    const { stack } = await client.runMethod(
      address,
      'get_record',
      args
    );
    const recordBalance = stack.readNumber();
    return recordBalance;
  };

  const generateUniqueHash = async () => {
    const uniqueHashes = await Promise.all([
      generateHash(
        new Date().toISOString(),
        wallet.address,
        amount.toString()
      ),
      generateHash(
        wallet.address,
        fee.toString(),
        new Date().toISOString()
      ),
      generateHash(
        (fee + amount).toString(),
        new Date().toISOString(),
        wallet.address
      )
    ]);
    const joinedHash = uniqueHashes.join('m')
    let rand = '';
    for (let i = 0; i < joinedHash.length; i++) {
      if (Math.round(Math.random()) === 0) {
        rand += joinedHash[i].toLowerCase();
      } else {
        rand += joinedHash[i].toUpperCase();
      }
    }
    return rand;
  };

  const handleDeposit = async () => {
    if (!wallet?.address) return;
    setIsDepositing(true);
    setGeneratedHash(null);

    try {
      let stakingAddr = '';
      switch (selectedAmount) {
        case 0.5:
          stakingAddr = import.meta.env.VITE_STAKING_ADDRESS_0_5;
          break;
        case 5:
          stakingAddr = import.meta.env.VITE_STAKING_ADDRESS_5;
          break;
        case 50:
          stakingAddr = import.meta.env.VITE_STAKING_ADDRESS_50;
          break;
        default:
          throw new Error('Invalid pool selected');
      }

      const STAKING_ADDRESS = Address.parse(stakingAddr);

      const client = await getClient();

      let uniqueHash = await generateUniqueHash();

      const contractData = await getContractData(client, STAKING_ADDRESS);

      while (Object.keys(contractData.records).includes(uniqueHash)) {
        uniqueHash = await generateUniqueHash();
      }

      const msg_body = beginCell()
        .storeUint(1, 32)
        .storeStringTail(uniqueHash)
        .endCell();

      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: STAKING_ADDRESS.toString(),
            amount: (contractData.fee + (amount * 10 ** 9)).toString(),
            payload: msg_body.toBoc().toString('base64'),
          }
        ]
      };

      await sendTransaction(transaction);

      await fetch('/api/addMix', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ user: Address.parse(wallet.address.toString()).toString(), pool: selectedAmount.toString() }),
      });

      setGeneratedHash(uniqueHash);
    } catch (error) {
      console.error('Transaction failed:', error);
    } finally {
      setIsDepositing(false);
    }
  };

  const handleWithdraw = async () => {
    if (!withdrawHash.trim() || !wallet?.address) return;
    setIsWithdrawing(true);
    setWithdrawError('');
    
    try {

      let stakingAddr = '';
      const client = await getClient();
      let recordBalance = 0;
      let poolAmount = 0.5;
      for (const pool of [import.meta.env.VITE_STAKING_ADDRESS_0_5, import.meta.env.VITE_STAKING_ADDRESS_5, import.meta.env.VITE_STAKING_ADDRESS_50]) {
        recordBalance = await getRecordData(client, Address.parse(pool), withdrawHash);
        if (recordBalance > 0) {
          stakingAddr = pool;
          break;
        }
        poolAmount *= 10;
      }

      if (!stakingAddr) {
        setWithdrawError('Invalid secret hash. Please check your secret hash and try again.');
        return;
      }

      const STAKING_ADDRESS = Address.parse(stakingAddr);

      let recipientAddress = Address.parse(finalRecipient !== '' ? finalRecipient : wallet.address.toString());

      if (useRelay) {
        const body = JSON.stringify({ 
          fromNetwork: 'ton',
          toNetwork: 'ton',
          fromCurrency: 'ton',
          toCurrency: 'ton',
          amount: (recordBalance / 10 ** 9).toString(),
          recipientAddress: recipientAddress.toString(),
        });


        const bridge = await fetch('/api/bridge', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
          },
          body,
        });
        const response = await bridge.json();
        const data = response.data;
        const status = response.status;

        if (status === 500) {
          setWithdrawError(`Relay error please try again`);
          console.log(data);
          return;
        }
        recipientAddress = Address.parse(data.depositAddress);
      }

      const msg_body = beginCell()
        .storeUint(2, 32)
        .storeStringTail(withdrawHash)
        .storeAddress(recipientAddress)
        .endCell();

      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: STAKING_ADDRESS.toString(),
            amount: toNano(0.005).toString(),
            payload: msg_body.toBoc().toString('base64'),
          }
        ]
      };

      await sendTransaction(transaction);

      await fetch('/api/addMix', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ user: Address.parse(wallet.address.toString()).toString(), pool: poolAmount.toString() }),
      });
      
      setWithdrawHash('');
      setRecipientAddress('');
      setWithdrawSuccess(true);
      setTimeout(() => setWithdrawSuccess(false), 5000);
      
    } catch (error) {
      console.error('Withdrawal failed:', error);
    } finally {
      setIsWithdrawing(false);
    }
  };

  const isValidAddress = (address) => {
    if (!address.trim()) return true; // Empty is valid
    try {
      Address.parse(address);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleRecipientChange = (e) => {
    const address = e.target.value;
    setRecipientAddress(address);
    if (!isValidAddress(address)) {
      setAddressError('Invalid TON address format');
    } else {
      setAddressError('');
    }
  };

  return (
    <div className="pool-card card-width" style={{ paddingTop: '1rem' }}>
      <RadioToggle 
        options={pools.map(pool => pool.label)}
        selectedValue={selectedPoolLabel}
        onChange={(value) => onPoolChange(pools.find(pool => pool.label === value).value)}
      />
      <div className="pool-details">
        <div className="detail-row">
          <span>Pool Size</span>
          <span>{formatTON(amount)} TON</span>
        </div>
        <div className="detail-row">
          <span>Fee (1%)</span>
          <span>{formatTON(fee)} TON</span>
        </div>
        <div className="detail-row">
          <span>Required $MIXER</span>
          <span>{requiredMixer.toLocaleString()}</span>
        </div>
        <div className="detail-row total">
          <span>Total</span>
          <span>{formatTON(total)} TON</span>
        </div>
      </div>
      <div className="pool-actions">
        {connected ? (
          <>
            {!isLoadingBalance && !hasSufficientMixer ? (
              <>
                <div className="insufficient-mixer">
                  <p>Insufficient $MIXER balance for deposit</p>
                  <p className="required-amount">Required: {requiredMixer.toLocaleString()} $MIXER</p>
                  <a 
                    href={LINKS.buy}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="buy-mixer-button"
                  >
                    <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <path d="M12 2v20M2 12h20" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                    Buy $MIXER
                  </a>
                </div>

                <div className="section-divider" role="separator">
                  <div className="divider-line"></div>
                  <span className="divider-text">or</span>
                  <div className="divider-line"></div>
                </div>

                <div className="withdraw-group">
                  <input
                    type="text"
                    className="hash-input"
                    placeholder="Secret Hash"
                    value={withdrawHash}
                    onChange={(e) => {
                      setWithdrawHash(e.target.value);
                      setWithdrawError('');
                    }}
                    disabled={isWithdrawing}
                  />
                  {withdrawError && (
                    <div className="withdraw-error">
                      {withdrawError}
                    </div>
                  )}
                  <input
                    type="text"
                    className="hash-input"
                    placeholder="TON Recipient Address (Optional)"
                    value={finalRecipient}
                    onChange={handleRecipientChange}
                    disabled={isWithdrawing}
                  />
                  {addressError && (
                    <div className="withdraw-error">
                      {addressError}
                    </div>
                  )}
                  <div className="withdraw-controls">
                    <button 
                      className="action-button withdraw"
                      onClick={handleWithdraw}
                      disabled={!connected || isWithdrawing || !withdrawHash.trim() || (finalRecipient.trim() && !isValidAddress(finalRecipient))}
                    >
                      {isWithdrawing ? (
                        <div className="loading-spinner" />
                      ) : (
                        <>
                          <span className="icon">↓</span>
                          Withdraw
                        </>
                      )}
                    </button>
                    <label className="relay-toggle">
                      <input
                        type="checkbox"
                        checked={useRelay}
                        onChange={(e) => setUseRelay(e.target.checked)}
                        disabled={isWithdrawing}
                      />
                      <span className="toggle-slider"></span>
                      <span className="toggle-label">Private Relay</span>
                    </label>
                  </div>
                  {useRelay && (
                    <div className="relay-disclaimer">
                      <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path d="M12 9v4m0 4h.01M12 2a10 10 0 110 20 10 10 0 010-20z" strokeWidth="2" strokeLinecap="round"/>
                      </svg>
                      {selectedAmount} TON will be routed {finalRecipient.trim() ? 'to the specified address' : 'to your connected wallet'} within 15 minutes, minus network fees
                    </div>
                  )}
                  {withdrawSuccess && (
                    <div className="withdraw-success">
                      <svg style={{ maxHeight: '30px' }} className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path d="M20 6L9 17l-5-5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      Your withdrawal is on the way
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <button 
                  className="action-button deposit"
                  onClick={handleDeposit}
                  disabled={isDepositing}
                >
                  {isDepositing ? (
                    <span className="loading-spinner"></span>
                  ) : (
                    <>
                      <svg className="icon" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M12 4L12 20M12 4L18 10M12 4L6 10" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                      </svg>
                      Deposit
                    </>
                  )}
                </button>

                {isDepositing ? (
                  <div className="hash-container">
                    <div className="loading-message">
                      <span className="loading-spinner"></span>
                      Processing deposit...
                    </div>
                  </div>
                ) : generatedHash && (
                  <div className="hash-container">
                    <p className="hash-warning">
                      Save this secret hash, <strong>it will only be displayed once</strong>. 
                      This will be required for withdrawing your funds.
                    </p>
                    <button 
                      className="hash-display"
                      onClick={handleCopyHash}
                      title="Click to copy hash"
                    >
                      {generatedHash}
                      {showCopiedHash && <span className="copied-tooltip">Copied!</span>}
                    </button>
                  </div>
                )}

                <div className="section-divider" role="separator">
                  <div className="divider-line"></div>
                  <span className="divider-text">or</span>
                  <div className="divider-line"></div>
                </div>

                <div className="withdraw-group">
                  <input
                    type="text"
                    className="hash-input"
                    placeholder="Secret Hash"
                    value={withdrawHash}
                    onChange={(e) => {
                      setWithdrawHash(e.target.value);
                      setWithdrawError('');
                    }}
                    disabled={isWithdrawing}
                  />
                  {withdrawError && (
                    <div className="withdraw-error">
                      {withdrawError}
                    </div>
                  )}
                  <input
                    type="text"
                    className="hash-input"
                    placeholder="TON Recipient Address (Optional)"
                    value={finalRecipient}
                    onChange={handleRecipientChange}
                    disabled={isWithdrawing}
                  />
                  {addressError && (
                    <div className="withdraw-error">
                      {addressError}
                    </div>
                  )}
                  <div className="withdraw-controls">
                    <button 
                      className="action-button withdraw"
                      onClick={handleWithdraw}
                      disabled={!connected || isWithdrawing || !withdrawHash.trim() || (finalRecipient.trim() && !isValidAddress(finalRecipient))}
                    >
                      {isWithdrawing ? (
                        <div className="loading-spinner" />
                      ) : (
                        <>
                          <span className="icon">↓</span>
                          Withdraw
                        </>
                      )}
                    </button>
                    <label className="relay-toggle">
                      <input
                        type="checkbox"
                        checked={useRelay}
                        onChange={(e) => setUseRelay(e.target.checked)}
                        disabled={isWithdrawing}
                      />
                      <span className="toggle-slider"></span>
                      <span className="toggle-label">Private Relay</span>
                    </label>
                  </div>
                  {useRelay && (
                    <div className="relay-disclaimer">
                      <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path d="M12 9v4m0 4h.01M12 2a10 10 0 110 20 10 10 0 010-20z" strokeWidth="2" strokeLinecap="round"/>
                      </svg>
                      {selectedAmount} TON will be routed {finalRecipient.trim() ? 'to the specified address' : 'to your connected wallet'} within 15 minutes, minus network fees
                    </div>
                  )}
                  {withdrawSuccess && (
                    <div className="withdraw-success">
                      <svg style={{ maxHeight: '30px' }} className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path d="M20 6L9 17l-5-5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                      Your withdrawal is on the way
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <button 
            className="action-button connect" 
            onClick={handleWalletClick}
            disabled={walletLoading || isDepositing || isWithdrawing}
          >
            {walletLoading ? 'Connecting...' : 'Connect TON Wallet'}
          </button>
        )}
      </div>
    </div>
  );
};

export default PoolCard; 