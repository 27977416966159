import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import PoolCard from './components/PoolCard/PoolCard';
import PoolStats from './components/PoolStats/PoolStats';
import Footer from './components/Footer/Footer';
import { useTonConnect } from './hooks/useTonConnect';
import { MIXER_TOKEN_ADDRESS, TON_API_URL } from './utils/constants';
import './styles/globals.css';

const App = () => {
  const { connected, wallet } = useTonConnect();
  const [mixerBalance, setMixerBalance] = useState(0);
  const [isLoadingBalance, setIsLoadingBalance] = useState(false);

  const pools = [
    { value: 0.5, fee: 0.005, label: '0.5 TON' },
    { value: 5, fee: 0.05, label: '5 TON' },
    { value: 50, fee: 0.5, label: '50 TON' }
  ];

  const [selectedPool, setSelectedPool] = useState(pools[0].value);
  const currentPool = pools.find(pool => pool.value === selectedPool);

  useEffect(() => {
    const fetchMixerBalance = async () => {
      if (connected && wallet) {
        setIsLoadingBalance(true);
        try {
          const response = await fetch(
            `${TON_API_URL}/accounts/${wallet.address}/jettons/${MIXER_TOKEN_ADDRESS}?supported_extensions=custom_payload`,
            {
              headers: {
                'Accept': 'application/json',
              }
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          
          if (data.balance) {
            const balance = Number(data.balance) / 1e9;
            setMixerBalance(balance);
          } else {
            setMixerBalance(0);
          }
        } catch (error) {
          console.error('Error fetching mixer balance:', error);
          setMixerBalance(0);
        } finally {
          setIsLoadingBalance(false);
        }
      } else {
        setMixerBalance(0);
      }
    };

    fetchMixerBalance();
  }, [connected, wallet]);

  return (
    <div className="app-container">
      <Header 
        mixerBalance={mixerBalance}
        isLoadingBalance={isLoadingBalance}
      />
      <main className="main-content">
        <PoolCard 
          pools={pools}
          selectedAmount={selectedPool}
          onPoolChange={setSelectedPool}
          amount={currentPool.value}
          fee={currentPool.fee}
          requiredMixer={50000}
          mixerBalance={mixerBalance}
          isLoadingBalance={isLoadingBalance}
        />
        <PoolStats poolSize={currentPool.value} />
      </main>
      <Footer />
    </div>
  );
};

export default App; 