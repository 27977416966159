import React, { useState } from 'react';
import './Footer.css';

const LINKS = {
  bridge: 'https://t.me/ton_mix_bot/bridge',
  telegram: 'https://t.me/tonmixerchat',
  twitter: 'https://x.com/tonmixbot'
};

const Footer = () => {
  const [showCopied, setShowCopied] = useState(false);

  const handleCopyAddress = async () => {
    try {
      await navigator.clipboard.writeText("EQAdFbynSUlzIlh_I4fXuYaer3rvY0TG0BK-NQZ-Y871pZoM");
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy address:', err);
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <button 
          className="wallet-address-container"
          style={{ position: 'relative', top: 'unset', left: 'unset', transform: 'unset', marginTop: '8px' }}
          onClick={handleCopyAddress}
          title="Click to copy"
        >
          <span className="contract-label">$MIXER CA:</span>
          <span className="contract-address">
            EQAdFbynSUlzIlh_I4fXuYaer3rvY0TG0BK-NQZ-Y871pZoM
          </span>
          {showCopied && <span className="copied-tooltip">Copied!</span>}
        </button>
        <div className="footer-links">
          <a href={LINKS.telegram} target="_blank" rel="noopener noreferrer" className="footer-link">
            <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path d="M21 5L2 12.5l7 2.5M21 5L15 21l-6-6M21 5L11 15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Telegram
          </a>
          <a href={LINKS.twitter} target="_blank" rel="noopener noreferrer" className="footer-link">
            <svg className="icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5 0-.28-.03-.56-.08-.83A7.72 7.72 0 0023 3z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Twitter
          </a>
        </div>
        <div className="footer-copyright">
          © 2025 $TON Mixer. All Rights Reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer; 