import React from 'react';
import './RadioToggle.css';

const RadioToggle = ({ options, selectedValue, onChange }) => {
  return (
    <div className="radio-toggle">
      {options.map((option) => (
        <button
          key={option}
          className={`toggle-option ${selectedValue === option ? 'active' : ''}`}
          onClick={() => onChange(option)}
          type="button"
        >
          {option}
        </button>
      ))}
    </div>
  );
};

export default RadioToggle; 