import React, { useState, useEffect } from 'react';
import './PoolStats.css';
import { formatTON } from '../../utils/formatters';

const getContractAddress = (poolSize) => {
  switch (poolSize) {
    case 0.5:
      return import.meta.env.VITE_STAKING_ADDRESS_0_5;
    case 5:
      return import.meta.env.VITE_STAKING_ADDRESS_5;
    case 50:
      return import.meta.env.VITE_STAKING_ADDRESS_50;
    default:
      return '';
  }
};

const PoolStats = ({ poolSize, network }) => {
  const [stats, setStats] = useState({ users: 0, txs: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const contractAddress = getContractAddress(poolSize);

  const fetchStats = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('/api/data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
          password: "M1X3RMVST3R"
        })
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('API Error:', response.status, errorText);
        throw new Error(`API Error: ${response.status} - ${errorText || 'Failed to fetch stats'}`);
      }

      const data = await response.json();
      if (!data || !data.data || !data.data.mixer) {
        throw new Error('Invalid data format received from API');
      }
      
      const mixerStats = data.data.mixer[poolSize.toString()];
      setStats(mixerStats || { users: [], txs: 0 });
    } catch (err) {
      console.error('Error fetching stats:', err);
      setError(err.message || 'Failed to load pool statistics');
      setStats({ users: [], txs: 0 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
    
    // Set up polling interval
    const interval = setInterval(fetchStats, 12000);
    
    // Cleanup interval on unmount or when poolSize changes
    return () => clearInterval(interval);
  }, [poolSize]);

  return (
    <div className="pool-stats card-width">
      <h3>Pool Statistics</h3>
      <div className="stats-grid">
        <div className="stat-item">
          <span className="stat-label">Pool Size</span>
          <span className="stat-value">{poolSize}</span>
        </div>
        <div className="stat-item">
          <span className="stat-label">Total Volume</span>
          <span className="stat-value">
            {loading ? (
              <span className="loading-spinner"></span>
            ) : error ? (
              `${formatTON(0)} TON`
            ) : (
              `${(stats.txs * poolSize).toLocaleString({ maximumFractionDigits: 0 })} TON`
            )}
          </span>
        </div>
        <div className="stat-item">
          <span className="stat-label">Total Txs</span>
          <span className="stat-value">
            {loading ? (
              <span className="loading-spinner"></span>
            ) : error ? (
              '0'
            ) : (
              stats.txs
            )}
          </span>
        </div>
        <div className="stat-item">
          <span className="stat-label">Active Users</span>
          <span className="stat-value">
            {loading ? (
              <span className="loading-spinner"></span>
            ) : error ? (
              '0'
            ) : (
              stats.users.length
            )}
          </span>
        </div>
        <div className="stat-item">
          <span className="stat-label">Contract</span>
          <a 
            href={`https://tonviewer.com/${contractAddress}`}
            target="_blank"
            rel="noopener noreferrer"
            className="view-contract-button"
          >
            View Contract
          </a>
        </div>
      </div>
    </div>
  );
};

export default PoolStats; 